.personal-form-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 20px;
    /* Adjust the gap between the inputs as needed */
    margin-top: 20px;
    margin-bottom: 20px;
}


.input-container {
    flex: 1 1 calc(50% - 50px);
    /* 50% width minus the gap */
    display: flex;
    align-items: center;
    /* Vertically center the input */
}

.input-container input[type="checkbox"] {
    padding: 0;
    margin: 0;
    width: 20px;
    cursor: pointer;
}

.checkbox-container {
    display: flex;
    align-items: center;
    gap: 5px;
    /* Optional: Adjust spacing between the checkbox and text */
}

.checkbox {
    margin: 0;
    /* Remove default margin for better alignment */
}

.input-container label {
    font-family: 'Solway';
    font-size: 0.9em;
    color: #666;
}

/* PersonalInfoForm.css */
.error-icon {
    color: red;
    margin-left: 10px;
}


@media only screen and (max-width: 768px) {

    .personal-form-container {
        flex-wrap: column;
        /* change the wrap to a column */
    }

    .input-container {
        flex: 1 1 100%;
        /* makes the container fill the width */
    }

    .input-container input[type="checkbox"] {
        margin-top: 20px;
        margin-bottom: 20px;
    }

}