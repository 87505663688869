.calendar-selection-container {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.calendar-selector-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 auto;
    max-width: 600px;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    border-radius: 10px;
    background-color: white;
    color: #111;
}

.button-container:hover {
    background-color: #ffd9b5;
}

.button-container-selected {
    background-color: #ffd9b5;
}


.button-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 4;
    padding: 0;
    margin: 0;
    height: 200px;
    width: 100%;
    border-radius: 10px;
}

.button-item p {
    font-size: 3em;
    padding: 60px 0;
    font-weight: 500;
    color: white;
}

.description-item {
    flex: 3;
    align-items: center;
    padding: 20px 0;
    margin: 0 auto;
}

.description-item p {
    padding: 10px;
    margin-top: 0px;
}

.selection-item {
    display: flex;
    align-items: center;
    /* Vertically center the content */
    justify-content: center;
    flex: 1;
    padding: 0;
    margin: 0 auto;
}

.selection-item .button {
    font-family: var(--font-family);
    font-size: 1em;
    font-weight: bold;
    padding: 10px 30px;
    background-color: var(--orange-selected-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.selection-item p {
    padding: 20px 0;
    margin-bottom: 5px;
    font-weight: 500;
}

.daily-button {
    background-image: url('/public/images/daily_bookings.jpg');
    background-size: cover;
    background-size: 125%;
    /* Increase the size to zoom in */
}

.monthly-button {
    background-image: url('/public/images/monthly_bookings.jpg');
    background-size: cover;
    background-size: 125%;
}

.calendar-selector-item:not(:last-child):hover {
    background-color: #faf0e8f1;
}


.booking-calendar {
    display: relative;
    margin: 50px auto;
}

.calendar-error {
    position: relative;
    margin-top: 40px;
    color: red;
    font-weight: 400;
    padding-left: 20px;
    font-size: 0.8em;
}

.calendar-error::before {
    position: absolute;
    content: '\f06a';
    font-family: 'FontAwesome';
    color: red;
    top: -1px;
    left: 0px;
}

.error-icon {
    color: red;
    margin-left: 10px;
}

.floor-plan {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 50px;
}

.floor-plan h2 {
    margin-top: 20px auto;
    text-align: center;
}

.floor-plan img {
    width: 100%;
    margin-top: 10px;
}

@media only screen and (max-width: 768px) {

    .calendar-selection-container {
        display: flex;
        /* Instead of block */
        flex-direction: column;
        /* Stack elements vertically */
        align-items: center;
    }

    .calendar-selector-item {
        height: 500px;
        /* Remove fixed height */
        width: 90%;
        /* Ensure proper responsiveness */
        margin-bottom: 20px;
        /* Reduce spacing */
    }

}