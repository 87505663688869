.booking-confirmation-container {
	padding: 0;
	margin-bottom: 50px;
}

.booking-confirmation-container p {
	text-align: left;
	margin: 0;
	padding: 0;
}

.booking-confirmation-container .info-block {
	margin-top: 20px;
}

.booking-confirmation-table {
	margin-top: 40px;
	margin-bottom: 40px;
}

.booking-confirmation-table table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
	table-layout: fixed;
	/* Add this line */
}

.booking-confirmation-table th,
.booking-confirmation-table td {
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	padding: 15px 0;
	text-align: left;
}

.booking-confirmation-table th {
	background-color: #f2f2f2;
	font-weight: bold;
}

.booking-confirmation-table td:nth-child(2) {
	width: 20%;
	text-align: right;
}

.booking-confirmation-table td:nth-child(3) {
	width: 20%;
	text-align: right;
}