.banner {
	position: relative;
	width: 100%;
	height: 500px;
	/* Adjust based on your desired banner height */
	overflow: hidden;
	/* Hide images outside the container */
}

.banner-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	/* Maintain aspect ratio and cover the container */
	opacity: 0;
	/* Initially hidden */
	transition: opacity 2s ease-in-out;
	/* Smooth fade-in effect over 2 seconds */
}

.banner-image.active {
	opacity: 1;
	/* Make the active image visible */
	z-index: 1;
	/* Ensure the active image is on top */
}

/* Base styles for panels row */
.panels-row {
	display: flex;
	flex-direction: column;
	gap: 100px;
	margin-top: 100px;
	margin-bottom: 50px;
}

/* Individual panel styling */
.panel {
	display: flex;
	align-items: flex-start;
	gap: 50px;
	/* Space between text and image */
}

/* Reverse layout for alternating rows */
.panel.reverse {
	flex-direction: row-reverse;
}

/* Panel text container */
.panel-text {
	flex: 1;
	/* Allows it to share space with the image */
	display: flex;
	flex-direction: column;
	/* Stack children (h2 and p) vertically */
	gap: 15px;
	/* Space between h2 and p */
	text-align: left;
	justify-content: flex-start;
	/* Ensure content stays at the top */
}

.panel-text h2 {
	margin-top: 20px;
}


/* Panel image styling */
.panel-image img {
	width: 100%;
	height: auto;
	max-width: 450px;
	margin-bottom: 30px;
	/* Limit image size */
	border-radius: 8px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Media query for Pixel 7 */
@media only screen and (max-width: 1040px) {
	.banner {
		height: 350px;
	}

	/* Base styles for panels row */
	.panels-row {
		display: flex;
		flex-direction: column;
		gap: 30px;
		margin-top: 20px;
	}

	.panel {
		display: block;
	}

	/* Panel text container */
	.panel-text {
		display: block;
		text-align: left;
	}

	/* Panel image styling */
	.panel-image img {
		margin-top: 20px;
		max-width: 700px;
	}

}


/* Media query for Pixel 7 */
@media only screen and (max-width: 768px) {
	.banner {
		height: 250px;
	}

	/* Base styles for panels row */
	.panels-row {
		display: flex;
		flex-direction: column;
		gap: 30px;
		margin-top: 20px;
	}

	.panel {
		display: block;
	}

	/* Panel text container */
	.panel-text {
		display: block;
		text-align: left;
	}

	/* Panel image styling */
	.panel-image img {
		margin-top: 20px;
		max-width: 100%;
	}

}