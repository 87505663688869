.about-us-container p:not(:last-child) {
    margin-bottom: 20px;
}

.about-us-container .page-header h1 {
    margin-bottom: 50px;
}

.about-us-image {
    display: flex;
    justify-content: center;
}

.about-us-image img {
    width: 100%;
    max-width: 400px;
    margin-top: 0px;
    margin-bottom: 70px;
}