.booking-selectors {
    margin-top: 70px;
    margin-bottom: 100px;
}

.booking-selections {
    margin-bottom: 100px;
}

.selected-desks-container {
    margin-top: 120px;
}

.selected-desks-container h3 {
    margin-top: 50px;
}

.booking-submit-button-container {
    text-align: right;
}

.booking-countdown {
    color: lightcoral;
}


.payment-container {
    margin: 50px 0;
}

.payment-details-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 30px;
    gap: 0px 20px;
}

.payment-details {
    flex: 1 1 calc(50% - 50px);
    /* 50% width minus the gap */
}

.payment-details p {
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 768px) {

    .payment-details-container {
        flex-wrap: column;
    }

    .payment-details {
        flex: 1 1 100%;
        margin-bottom: 30px;
    }

}