.contact-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    margin: 50px auto;
    /* Spacing between child elements */
    gap: 5%;
    /* Prevent exceeding viewport (subtract padding) */
    width: calc(100%);
    box-sizing: border-box;
}

.contact-form-input {
    position: relative;
}

.contact-form-input input,
textarea {
    width: 100%;
    text-align: left;
    box-sizing: border-box;
}

.contact-error-icon::after {
    position: absolute;
    content: '\f06a';
    font-family: 'FontAwesome';
    color: var(--red-error-color);
    top: 22px;
    right: 10px;
}

.map-container,
.form-container {
    flex: 1;
}

.map-container {
    margin-top: 10px;
}

.form-container textarea {
    min-height: 200px;
}

.contact-button-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
}

.contact-useful-points {
    margin-top: 100px;
    margin-bottom: 50px;
}

.contact-useful-points p {
    margin-top: 10px;
}


/* Media query for Pixel 7 */
@media only screen and (max-width: 768px) {

    .contact-container {
        display: block;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .map-container,
    .form-container {
        margin-top: 50px;
        padding: 0;
    }

    .contact-useful-points {
        margin-top: 70px;
        margin-bottom: 20px;
    }

}