.desk-wrapper {
    --desk-color-1: rgb(50, 205, 50);
    --desk-color-2: rgb(255, 105, 180);
    --desk-color-3: rgb(255, 215, 0);
    --desk-color-4: rgb(63, 145, 255);
    --desk-color-8: rgb(182, 153, 205);
    --desk-color-9: rgb(255, 160, 50);
    --desk-color-10: rgb(0, 255, 255);
    --desk-color-11: rgb(255, 20, 92);
}

.availability-summary-container {
    margin-top: 120px;
}

.availability-summary-item {
    margin-top: 80px;
}

.availability-summary-item h3 {
    text-align: center;
    margin-bottom: 20px;
}

.availability-summary-item p {
    text-align: center;
}


.available-date-panel {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Ensure uniform desk sizing */
    gap: 10px;
    justify-content: center;
}

.desk-list {
    display: contents;
    /* Ensures grid controls layout, not an extra flexbox */
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.desk-item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
}

.desk-item button {
    width: 100%;
    height: 100%;
    background-color: #cecdcd;
    color: rgb(97, 97, 97);
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 2em;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.desk-item button.desk-1:hover {
    background-color: var(--desk-color-1);
    transition: background-color 0.6s ease;
}

.desk-item button.desk-2:hover {
    background-color: var(--desk-color-2);
    transition: background-color 0.6s ease;
}

.desk-item button.desk-3:hover {
    background-color: var(--desk-color-3);
    transition: background-color 0.6s ease;
}

.desk-item button.desk-4:hover {
    background-color: var(--desk-color-4);
    transition: background-color 0.6s ease;
}

.desk-item button.desk-8:hover {
    background-color: var(--desk-color-8);
    transition: background-color 0.6s ease;
}

.desk-item button.desk-9:hover {
    background-color: var(--desk-color-9);
    transition: background-color 0.6s ease;
}

.desk-item button.desk-10:hover {
    background-color: var(--desk-color-10);
    transition: background-color 0.6s ease;
}

.desk-item button.desk-11:hover {
    background-color: var(--desk-color-11);
    transition: background-color 0.6s ease;
}


.desk-item button.desk-1.selected {
    background-color: var(--desk-color-1);
}

.desk-item button.desk-2.selected {
    background-color: var(--desk-color-2);
}

.desk-item button.desk-3.selected {
    background-color: var(--desk-color-3);
}

.desk-item button.desk-4.selected {
    background-color: var(--desk-color-4);
}

.desk-item button.desk-8.selected {
    background-color: var(--desk-color-8);
}

.desk-item button.desk-9.selected {
    background-color: var(--desk-color-9);
}

.desk-item button.desk-10.selected {
    background-color: var(--desk-color-10);
}

.desk-item button.desk-11.selected {
    background-color: var(--desk-color-11);
}



@media only screen and (max-width: 768px) {
    .availability-summary-container {
        margin-top: 20px;
    }
}