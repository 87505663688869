footer {
    margin-top: 50px;
    background-color: #f7f7f7;
    text-align: center;
    padding: 30px 0;
    width: 100%;
    /* Ensure footer spans full width */
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    /* Optional: Add slight shadow for separation */
    font-size: 0.85em;
}

footer p {
    padding: 0;
}

footer a {
    text-decoration: none;
    padding: 5px 10px;
    transition: background-color 0.3s ease;
}

footer a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    footer {
        display: none;
    }
}