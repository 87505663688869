@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Solway:wght@300;400;500;700;800&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

/* Ensure the entire app layout supports a sticky footer and proper width */
html,
body,
#root,
.App {

    height: 100%;
    /* Full height for the viewport */
    margin: 0;
    /* Remove default margin for a clean layout */
    width: 100%;
    /* Ensure full width of the viewport */
    display: flex;
    flex-direction: column;
    color: #111;

    font-family: 'Solway';

    --font-family: 'Solway';
    --orange-selected-color: #fa973b;
    --orange-hover-color: #fabc81;
    --orange-disabled-color: #fabc81;
    --red-error-color: red;

}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* Full height of the viewport */
}

.content {
    flex: 1;
    /* Ensure the content area takes up remaining space */
}

/* Prevent horizontal overflow */
html,
body {
    overflow-x: hidden;
    /* Prevent scrolling horizontally */
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

p {
    font-weight: 300;
    font-style: normal;
    font-size: 1em;
    line-height: 1.6;
    margin: 0;
    padding-bottom: 16px;
}

a {
    font-weight: 500;
    font-size: 1em;
    text-decoration: none;
    color: var(--orange-selected-color);
    /* Default link colour */

}

a:hover {
    text-decoration: underline;
}

h1 {
    font-weight: 500;
    font-size: 2.7em;
    margin: 80px auto;
    text-align: center;
    color: #555;
}

h2 {
    font-weight: 500;
    font-size: 1.7em;
    margin-top: 50px;
    margin-bottom: 10px;
    color: #555;
    text-align: left;
}

h3 {
    font-weight: 500;
    font-size: 1.3em;
    margin: 0;
    padding: 0;
    color: #555;
    text-align: left;
}

h4 {
    font-weight: 500;
    font-size: 1.1em;
    margin: 0;
    padding: 0;
    color: #555;
    text-align: left;
}

table {
    font-weight: 300;
    font-style: normal;
    font-size: 1em;
    line-height: 1.6;
    margin: 0;
    padding: 0;
}

/*  Style to manage the main content on a page */
.page {
    width: 1040px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.page-header {
    margin: 0;
    padding: 0;
}

.page-content {
    margin: 0 0 50px 0;
    padding: 0;
}

/* 
	General button styles
*/

input,
textarea {
    font-family: var(--font-family);
    font-size: 0.9em;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #f0f0f0;
    background-color: #f9f9f980;
    border-radius: 4px;
}

input:focus,
textarea:focus {
    border: 1px solid #c0c0c0;
    outline: none;
}

button {
    font-family: var(--font-family);
    font-size: 1em;
    font-weight: bold;
    padding: 10px 30px;
    background-color: var(--orange-selected-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: var(--orange-selected-color);
    transition: background-color 0.6s ease;
}

button:disabled {
    background-color: var(--orange-disabled-color);
    transition: background-color 0.3s ease;
    cursor: not-allowed;
}

fieldset {
    display: flex;
    border: none;
    margin: 0;
    padding: 0;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.modal-content p {
    font-size: 1em;
    margin-bottom: 15px;
    padding: 0;
}

/*
    The 1040px break point is matched to the 
    default .page size so it transitions
    seemlessly ...
*/
@media only screen and (max-width: 1040px) {
    .page {
        width: 100%;
    }
}


@media only screen and (max-width: 768px) {

    .content {
        padding-bottom: 100px;
        /* Ensure there is some white space at the bottom of the page when the footer is not shown on small screens */
    }

    .page-header {
        display: none;
    }

    .page-content {
        margin-top: 50px;
    }

    h2 {
        font-size: 1.6em;
    }

    p {
        font-size: 1.1em;
    }
}

@media (hover: none) {

    /* Remove hover effects */
    button:hover {
        background-color: var(--orange-selected-color);
    }
}