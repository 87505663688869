.navbar {
    /* Optional: Constrain content width */
    padding: 15px 0;
    width: 100%;
    border-bottom: 1px solid #e7e7e7;
}

.navbar a {
    color: #555;
}

.navbar-container {
    max-width: 1000px;
    /* Center the navigation bar */
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Logo container */
.navbar-logo {
    flex-shrink: 0;
    /* Prevent the logo from shrinking */
    margin-top: 10px;
}

.navbar-logo .logo {
    height: 34px;
    /* Adjust logo size */
    max-width: 100%;
}

.navbar-hamburger {
    display: none;
    cursor: pointer;
}

.hamburger-icon {
    margin-right: 20px;
    font-size: 2.75em;
    color: #555;
}

/* Links container */
.navbar-links {
    display: flex;
    gap: 5px;
    /* Space between links */
}

.navbar-links.open {
    display: block;
}

/* Link styling */
.navbar-links a {
    text-decoration: none;
    font-size: 1.1em;
    padding: 5px 10px;
    transition: background-color 0.3s ease;
}

.navbar-links a:hover {
    background-color: var(--orange-hover-color);
    /* Same hover effect */
    border-radius: 5px;
}

@media (max-width: 768px) {

    .navbar-logo .logo {
        height: 30px;
        margin-left: 20px;
    }

    .navbar-hamburger {
        display: block;
    }

    .hamburger-icon {
        font-size: 2em;
    }

    .navbar-links {
        display: none;
        flex-direction: column;
        gap: 10px;
    }

    .navbar-links.open {
        display: none;
    }

    .navbar-modal {
        position: fixed;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        z-index: 1000;
        padding-top: 20px;
    }

    .navbar-modal-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

    }

    .close-button {
        position: absolute;
        top: 12px;
        right: 20px;
        font-size: 2.5em;
        cursor: pointer;
        color: white;
    }

    .navbar-modal-content a {
        color: white;
        padding: 7px 20px;
        text-decoration: none;
        font-size: 1.1em;
    }
}