/* React Date Picker overrides */

.custom-input {
    box-shadow: none !important;
    transform: scale(1.2);
    margin-top: 30px;
}

.custom-input .rmdp-arrow {
    border-color: var(--orange-selected-color);
}

.custom-input .rmdp-calendar :focus {
    outline-color: var(--orange-selected-color);
}

.custom-input .rmdp-arrow-container:hover {
    background-color: var(--orange-selected-color);
}

.custom-input .rmdp-week-day {
    color: var(--orange-selected-color);
    cursor: default;
    font-size: 0.8em;
    font-weight: 500;
}

.custom-input .rmdp-day.rmdp-today span {
    background-color: #fddec0f1;
    color: #fff;
}

.custom-input .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: var(--orange-selected-color);
    box-shadow: 0 0 3px #8798ad;
    color: #fff;
}

.custom-input .rmdp-range {
    background-color: var(--orange-selected-color);
    box-shadow: 0 0 3px #ffd0a4f1;
    color: #fff;
}


@media (hover: hover) {
    .custom-input .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
        background-color: #f8ac64f1;
        color: #fff;
    }
}